@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,600&display=swap');
@import 'swiper/swiper-bundle.css';

body{
  overflow: overlay;
}

input , textarea{

  &:focus{
    outline: none !important;
  }

}

a, a:hover{
  text-decoration:none;
}

//swiper navigasyon
.swiper-pagination-bullet{
  opacity: 0.5;
  width: 6px;
  height: 6px;

  &-active{
    width: 50px;
    height: 6px;
    border-radius: 90px;
    opacity: 1;
  }
}


.flex-1{
  flex: 1;
}

.width-fit-content{
  width: fit-content;
}
.width-50px{
  width: 50px;
}
.width-84px{
  width: 84px;
}
.width-355px{
  width: 355px;
}
.width-120px{
  width: 120px;
}
.width-135px{
  width: 135px;
}
.width-165px{
  width: 165px;
}
.width-220px{
  width: 220px;
}
.min-w-255px{
  min-width: 255px;
}
.max-w-160px{
  max-width: 160px;
}
.max-w-180px{
  max-width: 180px;
}
.max-w-260px{
  max-width: 260px;
}
.max-w-315px{
  max-width: 315px;
}
.max-w-360px{
  max-width: 360px;
}
.max-w-390px{
  max-width: 390px;
}
.max-w-455px{
  max-width: 455px;
}
.max-w-480px{
  max-width: 480px;
}
.max-w-500px{
  max-width: 500px;
}
.max-w-555px{
  max-width: 555px;
}
.max-w-640px{
  max-width: 640px;
}
.max-w-810px{
  max-width: 810px;
}
.max-w-740px{
  max-width: 740px;
}
.max-w-940px{
  max-width: 940px;
}
.min-h-100px{
  min-height: 100px;
}
.min-h-454px{
  min-height: 454px;
}
.vw-100{
  width: 100vw;
}
.height-0{
  height: 0;
}
.height-5px{
  height: 5px;
}
.height-25px{
  height: 25px;
}
.height-30px{
  height: 30px;
}
.height-50px{
  height: 50px;
}
.height-100px{
  height: 100px;
}
.height-150px{
  height: 150px;
}
.height-250px{
  height: 250px;
}
.height-263px{
  height: 263px;
}
.height-290px{
  height: 290px;
}
.height-296px{
  height: 296px;
}
.height-320px{
  height: 320px;
}
.height-350px{
  height: 350px;
}
.height-385px{
  height: 385px;
}
.height-450px{
  height: 450px;
}
.height-500px{
  height: 500px;
}
.height-530px{
  height: 530px;
}


.cursor-pointer{
  cursor: pointer;
}

.overflow-y-auto{
  overflow-y: auto;
}

//z-index
.z-index-3{
  z-index: 3;
}
.z-index-5{
  z-index: 5;
}
.z-index-10{
  z-index: 10;
}
.z-index-15{
  z-index: 15;
}


//font
.font-f-cormorant{
  font-family: 'Cormorant', serif;
}

//

//font-weight
.font-w-300{
  font-weight: 300;
}
.font-w-500{
  font-weight: 500;
}
.font-w-600{
  font-weight: 600;
}
.font-w-900{
  font-weight: 900;
}

//font-size
.font-s-10px{
  font-size: 10px;
}
.font-s-12px{
  font-size: 12px;
}
.font-s-13px{
  font-size: 13px;
}
.font-s-16px{
  font-size: 16px;
}
.font-s-18px{
  font-size: 18px;
}
.font-s-20px{
  font-size: 20px;
}
.font-s-22px{
  font-size: 22px;
}
.font-s-24px{
  font-size: 24px;
}
.font-s-25px{
  font-size: 25px;
}
.font-s-26px{
  font-size: 26px;
}
.font-s-28px{
  font-size: 28px;
}
.font-s-30px{
  font-size: 30px;
}
.font-s-35px{
  font-size: 35px;
}
.font-s-40px{
  font-size: 40px;
}
.font-s-55px{
  font-size: 55px;
}
.font-s-95px{
  font-size: 95px;
}

//line-height
.line-h-1-1{
  line-height: 1.1;
}
.line-h-12px{
  line-height: 12px;
}
.line-h-16px{
  line-height: 16px;
}
.line-h-20px{
  line-height: 20px;
}
.line-h-21px{
  line-height: 21px;
}
.line-h-22px{
  line-height: 22px;
}
.line-h-23px{
  line-height: 23px;
}
.line-h-25px{
  line-height: 25px;
}
.line-h-28px{
  line-height: 28px;
}
.line-h-30px{
  line-height: 30px;
}
.line-h-33px{
  line-height: 33px;
}
.line-h-60px{
  line-height: 60px;
}

.letter-spacing-m-4-5{
  letter-spacing: -4.5px;
}
.letter-spacing-4-5{
  letter-spacing: 4.5px;
}
.letter-spacing-7-5{
  letter-spacing: 7.5px;
}


//line-clamp
.line-clamp-1, .line-clamp-2, .line-clamp-3{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.line-clamp-1{
    -webkit-line-clamp: 1;
}
.line-clamp-2{
    -webkit-line-clamp: 2;
}
.line-clamp-3{
    -webkit-line-clamp: 3;
}


//padding-margin


.p-20px{
  padding: 20px;
}
.p-35px{
  padding: 35px;
}
.pb-70px{
  padding-bottom: 70px;
}
.pt-30px{
  padding-top: 30px;
}
.px-10px{
  padding-left: 10px;
  padding-right: 10px;
}
.m-0{
  margin: 0;
}
.my-6{
  margin-top: 100px;
  margin-bottom: 100px;
}
.mx-7px{
  margin-right: 7px !important;
  margin-left: 7px !important;
}
.mt-6{
  margin-top: 85px;
}
.mt-30px{
  margin-top: 30px;
}
.mt-50px{
  margin-top: 50px;
}
.mt-160px{
  margin-top: 160px;
}
.mb-30px{
  margin-bottom: 30px;
}
.mb-38px{
  margin-bottom: 38px;
}
.mb-50px{
    margin-bottom: 50px;
}
.mb-75px{
  margin-bottom: 75px;
}
.mb-85px{
  margin-bottom: 85px;
}
.mb-90px{
  margin-bottom: 90px;
}
.mb-100px{
  margin-bottom: 100px;
}
.mt-40px{
  margin-top: 40px;
}
.mt-70px{
  margin-top: 70px;
}
.mb-35{
  margin-bottom: 35px;
}
.mb-133px{
  margin-bottom: 133px;
}


//btn-padding
.btn-p-15px-30px{
  padding: 15px 30px;
}

//box-shadow
.box-shadow-4-20{
  box-shadow: 0px 4px 20px rgba(6, 78, 177, 0.2);
  -webkit-box-shadow: 0px 4px 20px rgba(6, 78, 177, 0.2);
}
.box-shadow-4-20-2{
  box-shadow: 0px 4px 20px rgba(6, 78, 177, 0.05);
  -webkit-box-shadow: 0px 4px 20px rgba(6, 78, 177, 0.05);
}
.box-shadow-4-20-3{
  box-shadow: 0px 4px 20px rgba(6, 78, 177, 0.1);
  -webkit-box-shadow: 0px 4px 20px rgba(6, 78, 177, 0.1);
}
.box-shadow-4-40{
  box-shadow: 0px 4px 40px rgba(6, 78, 177, 0.4);
  -webkit-box-shadow: 0px 4px 40px rgba(6, 78, 177, 0.4);
}
.box-shadow-15-50{
  box-shadow: 0px -15px 50px rgba(6, 78, 177, 0.15);
  -webkit-box-shadow:  0px -15px 50px rgba(6, 78, 177, 0.15);
}

//border
.border-radius{
  border-radius: 10px;
}
.border-radius-5px{
  border-radius: 5px;
}
.border-radius-50{
  border-radius: 50%;
}
.border-radius-90px{
  border-radius: 90px;
}
.top-left-radius{
  border-top-left-radius: 10px;
}
.top-right-radius{
  border-top-right-radius: 10px;
}
.bottom-left-radius{
  border-bottom-left-radius: 10px;
}
.bottom-right-radius{
  border-bottom-right-radius: 10px;
}
.border-none{
  border: none;
}

.opacity-0{
  opacity: 0;
}
.opacity-0-3{
  opacity: 0.3;
}
.opacity-0-5{
  opacity: 0.5;
}

.transition-0-3{
  transition: 0.3s;
}

.scale-1{
  transition: 0.2s;
  &:hover{
    transform: scale(1.03);
  }
}

.nav-link{
  padding: 0.5rem 0.7em;
}
.nav-button{
  padding: 0.5rem 2em;
}


.bg-image{
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.background-fixed{
  background-attachment: fixed !important;
}

.object-fit-cover{
  object-fit: cover;
}




//swiper
.swiper-slide{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}


//fade-effect

.component-fade-enter-active{
  transition: opacity 2s ease;
}
.component-fade-leave-active {
  opacity: 0;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

.login, .register{
  width: 50vw;
  min-height: 100vh;
}


.login-logo{
  width: 263px;
  height: 52px;
}

.form-vue-select {

  input {
    &::placeholder {
      color: #7684aa;
      font-size: 12px;
    }
  }

}

.form-error-border{
  border-color: #e3342f !important;
  padding-right: calc(1.6em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.vs__dropdown-toggle {
  padding: 0.75rem 1rem !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid #7684aa !important;
  padding-left: 30px !important;
  color: #7684aa !important;
  font-size: 12px !important;
}

.form-input, .form-item{

  input{
    height: 50px;
    border-radius: 10px;
    border: 1px solid #7684AA;
    padding-left: 70px;
    padding-right: 70px;

    &::placeholder{
      color: #7684AA;
      font-size: 12px;
    }
  }

  .input-left-svg{
    position: absolute;
    top: 13px;
    left: 30px;
  }
  .input-right-svg{
    position: absolute;
    top: 13px;
    right: 30px;
  }

}


@media (max-width: 992px){
  .login, .register, .login-info{
    width: 100vw !important;
  }

  .mt-m-60px{
    margin-top: 60px;
  }
}

@media (max-width: 768px){
  .calculate-score-main{
    background-size: 300vw 320px !important;
    background-position: top !important;
  }

  .mt-md-90px{
    margin-top: 90px !important;
  }
  .mt-md-100px{
    margin-top: 100px !important;
  }

  .w-m-50{
    width: 50% !important;
  }

  .w-m-100{
    width: 100% !important;
  }
  .font-s-14px-m{
    font-size: 14px !important;
  }

  .calculate-forms{
    h5{
      font-size: 16px !important;
    }
    label{
      font-size: 12px !important;
    }
  }

  .w-m-100{
    width: 100% !important;
  }

  .font-s-m-12px{
    font-size: 12px !important;
  }
  .line-h-m-21px{
    line-height: 21px !important;
  }
}


@media (max-width: 576px){

  .channel-card-mobile{
    max-width: 176px !important;
  }
  .mt-m-100px{
    margin-top: 100px;
  }

}

@media (max-width: 400px){

  .channel-card-mobile{
    max-width: 280px !important;
  }

}

//calculater-pages
.calculater-bottom-btn{
  width: 180px;
  height: 36px;
  display: flex;
  align-items: center;
  top: 20px;
  background-color: white;
}
.result-content {
  width: 140px;
  height: 140px;
}
.btn-result {
  width: 204px;
  height: 41px;
}
.result-box {
  background: rgba(251, 252, 255, 0.5);
  backdrop-filter: blur(10px);
}

.settings{
  input[type="text"],
  select,
  input[type="date"],
  input[type="password"],
  input[type="email"],
  input[type="tel"]
  {
    height: 45px;
    border: 1px solid #afbbdb;
    border-radius: 10px;
    padding: 13px 10px;
    color: #7684aa;
    font-size: 12px;
  }

  input[type="text"] {
    &::placeholder {
      font-size: 12px;
      color: #7684aa;
    }
  }

  textarea {
    border: 1px solid #afbbdb;
    height: 112px;
    padding-left: 13px;
    color: #7684aa;
    font-size: 12px;
    resize: initial;

    &::placeholder {
      font-size: 12px;
      color: #afbbd6;
    }
  }
}


.modal-app{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg{
    position: absolute;
    z-index: 1000001;
    width: 100%;
    height: 100%;
    background: #00000023;
    cursor: pointer;
  }

  &-content{
    position: relative;
    z-index: 1000002;
    padding: 1rem;
    border-radius: 1rem;
    max-width: 80vw;
    max-height: 80vh;

    &-close{
        position: absolute;
        z-index: 1000003;
        right: -10px;
        top: -10px;
        border: 2px solid rgb(150, 150, 150);
        font-weight: bold;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: black;
    }
  }
}
